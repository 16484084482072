/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-danger */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Button from '../Button';
import Modal from '../Modal';

const ModalTermsUse: React.FC<any> = () => {
  const { user, data, setData } = useAuth();
  const [termsUse, setTermsUse] = React.useState<any>(undefined);
  const [show, setShow] = React.useState<boolean>(false);
  const { push } = useHistory();
  const getPrivacyPolicy = React.useCallback(async () => {
    try {
      const response = await api.get(`/accounts/terms-of-use/`);
      setTermsUse(response?.data);
      setShow(true);
    } catch (e: any) {
      console.log({ e });
    }
  }, []);
  const handleLogout = () => {
    alert('Os termos de uso são obrigatórios. Entre em contato conosco através do FALE CONOSCO.');
    localStorage.removeItem('@Transfacil:token');
    localStorage.removeItem('@Transfacil:user');
    push("fale-conosco")
    window.location.reload();
    setShow(!show)
  }
  React.useEffect(() => {
    if (user && !user?.accepted_terms_of_use) getPrivacyPolicy();
  }, [user]);
  if (!show) return null;
  return (
    <Modal
      title="Termos de Uso"
      handleClose={handleLogout}
      show={show}
      action={(
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={async () => {
              await api.post('/accounts/user-accepted-terms-of-use/', {
                terms_of_use: termsUse?.id,
              });
              data.user = {
                ...data.user,
                accepted_terms_of_use: true,
              }
              setData(data)
              localStorage.setItem('@Transfacil:user', JSON.stringify(data.user));
              setShow(!show);
            }}
            color="orange"
            padding="6"
            className="button"
            rounded
          >
            ACEITAR
          </Button>
        </div>
      )}
    >
      <div>
        <p style={{ color: "red", marginTop: "5px", marginBottom: "15px", border: "1px solid red", borderRadius: "16px", padding: "5px" }}>Caso não concorde com os termos clique em fechar e entre em contato conosco através do FALE CONOSCO</p>
        {termsUse?.text && (
          <div style={{ fontSize: 14, fontWeight: 'normal' }} dangerouslySetInnerHTML={{ __html: termsUse?.text }} />
        )}
      </div>
    </Modal>
  );
};

export { ModalTermsUse };

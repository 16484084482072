/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-template */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGerarTicketQrCode } from '../../components/buttonGerarTicketQrCode';
import DashboardLayout from '../../components/DashboardLayout';
import Loader from '../../components/Loader';
import api from '../../services/api';
import { MapHistory } from './MapHistory';
import RecargaHistorico from './RecargaHistorico';
import { ReissueTicketByValue } from './ReissueTicketByValue';
import { ButtonBuy, Container, TitleSection, QrCodeButtons, LinkHistory } from './styles';
import Card from '../../components/MyCards/Card';
import AccountsService from '../../services/accounts';

interface IQrCodeBalance {
  card_number: string
  id: number
  nickname?: string
  saldo: number
}

const QrCode: React.FC = () => {
  const history = useHistory()
  const servicesProfile = { AccountsService: new AccountsService() };
  const [qrCodeBalance, setQrCodeBalance] = React.useState<IQrCodeBalance | undefined>(undefined);
  const [newCardValue, setNewCardValue] = React.useState(undefined);
  const [newCardNumber, setNewCardNumber] = React.useState(undefined);
  const [qrcodeHistory, setQrcodeHistory] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  
  const QrCodeCreate = React.useCallback(async () => {
    try {
      const newCard = await api.post('/ticketing/qr-code/request/');
      setNewCardValue(newCard);
      const datas = newCard?.data?.card_number;
      const newDatas = datas?.slice(0, -1) + "-" + datas?.slice(-1);
      setNewCardNumber(newDatas)
    } catch (e) {
      console.log({ e });
    }
  },[]);

  const getQrCodeBalance = React.useCallback(async () => {
    try {
      const { data } = await api.get(`/ticketing/qr-code/balance/`);
      setQrCodeBalance(data);
    } catch (e) {
      console.log({ e });
    }
  }, []);
  const start = React.useCallback(async () => {
    try {
      await QrCodeCreate();
      await getQrCodeBalance();
    } catch (e) {
      console.log({ e });
    }
    
  },[]);
  const QrCodeHistorico = React.useCallback(() => {
    if(qrcodeHistory) {
      setQrcodeHistory(false);
    } else if(!qrcodeHistory) {
      setQrcodeHistory(true);
    }

  }, [qrcodeHistory]);
  React.useEffect(() => {
    start();
  }, []);

  React.useEffect(() => {
    async function getProfile() {
      try {
        const response = await servicesProfile.AccountsService.getProfile();
        if (
          response.data.address == null ||
          response.data.birth_date == null ||
          response.data.cpf == null ||
          response.data.email == null ||
          response.data.full_name == null
        )
          setLoading(false);
        localStorage.setItem('@Transfacil:user', JSON.stringify(response.data));
      } catch (err) {
        const { ...error }: any = err;

        setLoading(false);
      }
    }
    getProfile();
  }, []);
  if (!qrCodeBalance) return <Loader />
  return (
    <DashboardLayout>
      <Container>
        <TitleSection>
          <h1>Qr Code</h1>
          <Card
            key={newCardValue?.data?.id}
            cardNumber={newCardNumber}
            saldo={qrCodeBalance?.saldo}
            qrcode={true}
          />
          <p onClick={() => history.push(`/qr-code/RecargaHistorico`)} className='historico'>HISTÓRICO DE RECARGAS</p>
        </TitleSection>
        <QrCodeButtons>
          <ButtonBuy
            style={{ marginRight: 10}}
            onClick={() => history.push(`/qr-code/${qrCodeBalance?.id}/buy`)}
          >
            COMPRAR QR CODE
          </ButtonBuy>
         <ButtonGerarTicketQrCode />
         <ButtonBuy
            style={{ marginRight: 10 }}
            onClick={QrCodeHistorico}
          >
            HISTÓRICO DE QRCODES
          </ButtonBuy>
        </QrCodeButtons>
        {/* <div className='VirtualCard'style={{ marginBottom: 10 }}>
          <ReissueTicketByValue value={qrCodeBalance?.saldo} />
        </div> */}
        {qrcodeHistory &&
          <div style={{ marginBottom: 50 }}className='Table'>
            <MapHistory />
          </div>
        }
      </Container>
    </DashboardLayout>
  );
};

export { QrCode };

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { ImLocation2 } from 'react-icons/im';
import { FaPhoneAlt } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useToast } from '../../../hooks/toast';

import {
  Content,
  Main,
  FlexContainer,
  FormWrapper,
  GridContainer,
  DivAction,
  ActionActive,
} from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import CoreService from '../../../services/core';
import Select from '../../Select';

interface ContactSectionProps {
  isPage?: boolean;
  isButtonLarge?: boolean;
}

const ComplianceText: React.FC<ContactSectionProps> = ({
  isPage = false,
  isButtonLarge = false,
  ...rest
}) => {
  const services = { coreService: new CoreService() };
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [disable, setDisable] = useState(false);
  const [identificar, setIdentificar] = useState(false);
  const [eventValue, setEventValue] = useState('off');
  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        setDisable(true);

        const schema = Yup.object().shape({
          categoria: Yup.string().required('Modalidade é obrigatória'),
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um email válido'),
          phone: Yup.string(),
          comment: Yup.string().required('Mensagem é obrigatório'),
          card_number: Yup.string(),
        });
        const comment = `[${data?.categoria}] \n${data.comment}`;

        await schema.validate(data, {
          abortEarly: false,
        });

        await services.coreService.createContact(
          data.name,
          data.email,
          comment,
          data.categoria,
          data.phone || '',
          data.card_number,
        );
        addToast({
          type: 'success',
          title: 'Agradecemos seu contato',
          description: 'Sua mensagem é muito importante para nós!',
        });

        // formRef.current.reset();
        setDisable(false);

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } catch (err: any) {
        const { ...error } = err;
        setDisable(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Registro',
          description: `Ocorroeu um erro ao tentar registrar o Contato`,
        });
      }
    },
    [addToast, services],
  );

  const handleIdentify = useCallback(
    async data => {
      console.log(data);
      if (data === 'on') {
        setEventValue('on');
        setIdentificar(true);
      } else if (data === 'off') {
        setEventValue('off');
        setIdentificar(false);
      }
    },
    [identificar, eventValue],
  );
  return (
    <>
      <Main isPage={isPage} {...rest}>
        <Content>
          <GridContainer>
            <FlexContainer isPage={isPage}>
              <h1>Como funciona?</h1>
              <p>
                Buscando promover as melhores práticas de governança corporativa
                e gestão de riscos, em consonância com a Lei 12.846/13 (Lei
                Anticorrupção), o Transfácil disponibiliza um Canal de
                integridade exclusivo para o registro de relatos sobre situações
                como: atitudes antiéticas ou ilegais, violações ao Código de
                Integridade, atividades e comportamentos inapropriados,
                conflitos de interesses, fraudes, assédio moral ou sexual,
                suborno, corrupção, práticas discriminatórias de qualquer
                natureza ou descumprimento das leis vigentes.
              </p>
              <p>
                Nosso Canal de Integridade está aberto para o registro, apuração
                e tratamento de denúncias, pautado pelas premissas do Código de
                Integridade Transfácil, com total segurança, sendo garantidos o
                sigilo e anonimato ao denunciante.
              </p>
              <div className="contatoDiv">
                <p className="contato">
                  O Canal está disponível 24 horas por dia, 365 dias por ano,
                  podendo ser acessado por qualquer um dos meios a seguir:
                </p>
                <p>
                  <strong style={{ color: 'var(--purple)' }}>Website: </strong>{' '}
                  <a
                    href="https://transfacil.com.br/compliance"
                    target="_blank"
                    className="link"
                    rel="noreferrer"
                  >
                    www.transfacil.com.br/compliance{' '}
                  </a>{' '}
                </p>
                <p>
                  <strong style={{ color: 'var(--purple)' }}>E-mail: </strong>
                  compliancetransfacil@gmail.com
                </p>
                <div className="icon">
                  <p>Conheça o codigo de integridade do Transfácil </p>
                  <div className="divlink">
                    <a
                      href="/files/TRANSFACILCODIGO_INTEGRIDADE_Versão_IntegralO11024.pdf"
                      target="_blank"
                    >
                      <p className="aqui">Clique Aqui</p>
                      <PictureAsPdfIcon className="iconpdf" />
                    </a>
                  </div>
                </div>
              </div>
            </FlexContainer>
          </GridContainer>
        </Content>
      </Main>
    </>
  );
};

export default ComplianceText;

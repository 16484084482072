/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});
let isTokenInvalid = false;

api.interceptors.response.use(
  success => success,
  error => {
    console.log({ error });
    const { ...err } = error;
    const statusCode = error?.response.status;

    if ((statusCode === 401 || statusCode === 403) && !isTokenInvalid) {
      isTokenInvalid = true;
      alert('Token expirado, por favor realize o login novamente.');
      localStorage.removeItem('@Transfacil:token');
      localStorage.removeItem('@Transfacil:user');
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      if (err.response.data.detail === 'Token inválido.') {
        alert('Token expirado, por favor realize o login novamente.');
        localStorage.removeItem('@Transfacil:token');
        localStorage.removeItem('@Transfacil:user');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        if (err.response.data.message === 'Token inválido.') {
          alert('Token expirado, por favor realize o login novamente.');
          localStorage.removeItem('@Transfacil:token');
          localStorage.removeItem('@Transfacil:user');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      }
    }
    return Promise.reject(error);
  },
);

export default api;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import api from '../../services/api';
import { CheckBox } from '../Checkbox';
import Modal from '../Modal';

const CheckBoxPrivacyPolicy: React.FC<any> = () => {
  const [privacyPolicy, setPrivacyPolicy] = React.useState<any>(undefined);
  const [show, setShow] = React.useState<boolean>(false);
  const getPrivacyPolicy = React.useCallback(async () => {
    const response = await api.get(`/accounts/privacy-policy/`);
    setPrivacyPolicy(response?.data);
  }, []);
  React.useEffect(() => {
    getPrivacyPolicy();
  }, []);
  return (
    <div style={{ height: '50px', display: 'flex', justifyContent: 'left' }}>
      <CheckBox
        name="privacy_policy"
        options={[
          {
            id: 'privacy_policy',
            label: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#4d4a48',
                  fontSize: '16px',
                  opacity: '0.8',
                }}
                // onClick={() => setShow(true)}
              >
                <div
                  style={{
                    marginLeft: 5,
                    marginBottom: 3,
                    height: 0,
                  }}
                >
                  Li e concordo com a
                </div>
                <a
                  style={{ marginLeft: 5, color: '#472b74' }}
                  href="/politica-privacidade"
                  target="_blank"
                >
                  Política de Privacidade
                </a>
              </div>
            ),
            value: privacyPolicy?.id,
          },
        ]}
      />
      {show && (
        <Modal
          title="Política de Privacidade"
          handleClose={() => setShow(!show)}
          show={show}
        >
          <div>
            <p style={{ fontSize: 14, fontWeight: 'normal' }}>
              {privacyPolicy?.text}
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export { CheckBoxPrivacyPolicy };

import React from 'react';
import { Container, Content, Blur } from '../../styles/components';
import Header from '../../components/Home/Header';
import Navbar from '../../components/Home/NavBar';
import { TitleSection, GridContainer } from './styles';
import Footer from '../../components/Home/Footer';
import ComplianceForm from '../../components/ComplianceForm';
import ComplianceText from '../../components/ComplianceForm/ComplianceText';

const CanalDeDenuncias: React.FC = () => {
  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content>
            <Navbar />
            <TitleSection>
              <h1>Canal de Denúncias - Compliance</h1>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <GridContainer>
            <ComplianceText />
            {/* <ComplianceForm /> */}
          </GridContainer>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default CanalDeDenuncias;

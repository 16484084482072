/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
import React, { useCallback, useEffect } from 'react';

import {
  useLocation,
  useHistory,
  useRouteMatch,
  Switch,
  Route,
  matchPath,
} from 'react-router-dom';
import Loader from '../../components/Loader';
import DashboardLayout from '../../components/DashboardLayout';
import {
  TitleSection,
  Main,
  ContainerCartao,
  TransfacilCard,
  ModalContentBanner,
} from './styles';

import TicketingService, { Card } from '../../services/ticketing';

import ListCard from '../../components/MyCards/ListCard';
import RegisterCard from '../../components/MyCards/RegisterCard';
import BlockCard from '../../components/MyCards/BlockCard';
import RechargerCard from '../../components/MyCards/RechargerCard';
import InformationsCard from '../../components/MyCards/InformationsCard';
import LossOrTheft from '../../components/MyCards/LossOrTheft';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import AccountsService from '../../services/accounts';
import ModalText from './Modal';
import Button from '../../components/Button';

const MyCards: React.FC = () => {
  const { path, url } = useRouteMatch();

  const { pathname } = useLocation();
  const { push } = useHistory();
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const services = { ticketingService: new TicketingService() };
  const servicesProfile = { AccountsService: new AccountsService() };

  const [cards, setCards] = React.useState<Card[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [modalPhone, setModalPhone] = React.useState<boolean>(false);
  const [modalCpf, setModalCpf] = React.useState<boolean>(false);
  const [phones, setPhones] = React.useState([]);
  const [cpfs, setCpfs] = React.useState([]);
  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  const getCards = React.useCallback(async () => {
    try {
      const response = await services.ticketingService.getActiveCards();

      const nonBloquedCards = response.data.results.filter(
        (card: any) => !card.is_blocked,
      );
      setCards(nonBloquedCards);
    } catch (err) {
      console.log({ err });
      const { ...error }: any = err;
      addToast({
        type: 'error',
        title: `${
          error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Erro no Servidor'
        }`,
        description: `${
          error?.response?.data?.sub_message
            ? error?.response?.data?.sub_message
            : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
        }`,
      });
      if (err) {
        signOut();
      }
    } finally {
      setLoading(false);
    }
  }, [phones, cpfs, modalCpf, modalPhone]);

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await servicesProfile.AccountsService.getProfile();
        if (
          response.data.address == null ||
          response.data.birth_date == null ||
          response.data.cpf == null ||
          response.data.email == null ||
          response.data.full_name == null
        )
          setLoading(false);
        localStorage.setItem('@Transfacil:user', JSON.stringify(response.data));
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Erro no Servidor'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
          }`,
        });
        if (err) {
          signOut();
        }
        setLoading(false);
      }
    }
    getProfile();
  }, []);

  const handleUrlMatch = (activeUrl: string): boolean => {
    const match = matchPath(pathname, {
      path: activeUrl,
      exact: true,
    });

    return match ? match.isExact : false;
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Switch>
            <Route exact path={path}>
              <TitleSection>
                <h1>Meus Cartões</h1>
              </TitleSection>
              <Main>
                <ContainerCartao>
                  {/* <TransfacilCard
                    active={handleUrlMatch(`${url}/recarregar`)}
                    onClick={() => handleRedirect(`${url}/recarregar`)}
                  >
                    RECARREGAR
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/registrar`)}
                    onClick={() => handleRedirect(`${url}/registrar`)}
                  >
                    CADASTRAR CARTÃO
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/perda-ou-roubo`)}
                    onClick={() => handleRedirect(`${url}/perda-ou-roubo`)}
                  >
                    PERDA OU ROUBO
                  </TransfacilCard> */}
                  <h3 className="cardmenu">
                    * Clique no CARTÃO para alterar o{' '}
                    <strong className="destaque">
                      apelido, ver o histórico de recargas e histórico de uso{' '}
                    </strong>
                    ou clique em RECARREGAR para fazer uma recarga.
                  </h3>
                </ContainerCartao>
                <ListCard cards={cards} />
                <h4 className="informacoes">
                  * Seu saldo pode ser atualizado em até 72 horas, podendo
                  exceder esse prazo em caso de problemas técnicos.
                </h4>
                <h4 className="informacoes">
                  * O numero do cartão poderá ser encontrado no verso no cartão
                  físico.
                </h4>
                <h4 className="informacoes">
                  * Informações como histórico de recargas e histórico de uso
                  podem ser vistas clicando no cartão.
                </h4>
                <h4 className="informacoes">
                  * Não é possível recuperar os créditos do Cartão BHBUS ao
                  Portador. Função disponível apenas no cartão BHBUS
                  IDENTIFICADO.
                </h4>
              </Main>
            </Route>
          </Switch>
          <Switch>
            <Route path={`${path}/recarregar`}>
              <TitleSection>
                <h1>Meus Cartões</h1>
              </TitleSection>
              <Main>
                <ContainerCartao>
                  {/* <TransfacilCard
                    active={handleUrlMatch(`${url}/recarregar`)}
                    onClick={() => handleRedirect(`${url}/recarregar`)}
                  >
                    RECARREGAR
                  </TransfacilCard> 
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/registrar`)}
                    onClick={() => handleRedirect(`${url}/registrar`)}
                  >
                    CADASTRAR CARTÃO
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/perda-ou-roubo`)}
                    onClick={() => handleRedirect(`${url}/perda-ou-roubo`)}
                  >
                    PERDA OU ROUBO
                  </TransfacilCard> */}
                </ContainerCartao>
                <RechargerCard cards={cards} />
              </Main>
            </Route>
            <Route path={`${path}/registrar`}>
              <TitleSection>
                <h1>Meus Cartões</h1>
              </TitleSection>
              <Main>
                <ContainerCartao>
                  {/* <TransfacilCard
                    active={handleUrlMatch(`${url}/recarregar`)}
                    onClick={() => handleRedirect(`${url}/recarregar`)}
                  >
                    RECARREGAR
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/registrar`)}
                    onClick={() => handleRedirect(`${url}/registrar`)}
                  >
                    CADASTRAR CARTÃO
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/perda-ou-roubo`)}
                    onClick={() => handleRedirect(`${url}/perda-ou-roubo`)}
                  >
                    PERDA OU ROUBO
                 </TransfacilCard> */}
                </ContainerCartao>
                <RegisterCard cards={cards} callback={getCards} />
              </Main>
            </Route>
            <Route path={`${path}/bloqueio`}>
              <TitleSection>
                <h1>Meus Cartões</h1>
              </TitleSection>
              <Main>
                <ContainerCartao>
                  {/* <TransfacilCard
                    active={handleUrlMatch(`${url}/recarregar`)}
                    onClick={() => handleRedirect(`${url}/recarregar`)}
                  >
                    RECARREGAR
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/registrar`)}
                    onClick={() => handleRedirect(`${url}/registrar`)}
                  >
                    CADASTRAR CARTÃO
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/perda-ou-roubo`)}
                    onClick={() => handleRedirect(`${url}/perda-ou-roubo`)}
                  >
                    PERDA OU ROUBO
                  </TransfacilCard> */}
                </ContainerCartao>
                <BlockCard cards={cards} />
              </Main>
            </Route>
          </Switch>
          <Switch>
            <Route path={`${path}/cartao`}>
              <Main>
                <InformationsCard cards={cards} callback={getCards} />
              </Main>
            </Route>
          </Switch>
          {/* <Switch>
            <Route path={`${path}/perda-ou-roubo`}>
              <TitleSection>
                <h1>Meus Cartões</h1>
              </TitleSection>
              <Main>
                <ContainerCartao>
                  {/* <TransfacilCard
                    active={handleUrlMatch(`${url}/recarregar`)}
                    onClick={() => handleRedirect(`${url}/recarregar`)}
                  >
                    RECARREGAR
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/registrar`)}
                    onClick={() => handleRedirect(`${url}/registrar`)}
                  >
                    CADASTRAR CARTÃO
                  </TransfacilCard>
                  <TransfacilCard
                    active={handleUrlMatch(`${url}/perda-ou-roubo`)}
                    onClick={() => handleRedirect(`${url}/perda-ou-roubo`)}
                  >
                    PERDA OU ROUBO
                  </TransfacilCard>
                </ContainerCartao>

                <LossOrTheft cards={cards} />
              </Main>
            </Route>
          </Switch> */}
        </>
      )}
      {/* {modalPhone && (
        <ModalText
          handleClose={() => setModalPhone(false)}
          show={modalPhone}
          title="Dados Incompletos"
        >
          <ModalContentBanner>
            <div className="text">
              <p>
                Verificamos que existem dados obrigatórios não preenchidos no
                seu cadastro.
              </p>
              <p>Complete seu cadastro antes de prosseguir.</p>
            </div>
            <Button
              color="orange"
              padding="3.1"
              rounded
              className="button-confirmar"
              type="button"
              onClick={() => handleRedirect('/meu-perfil')}
            >
              CADASTRAR
            </Button>
          </ModalContentBanner>
        </ModalText>
      )}
      {modalCpf && (
        <ModalText
          handleClose={() => setModalCpf(false)}
          show={modalCpf}
          title="Dados Incompletos"
        >
          <ModalContentBanner>
            <div className="text">
              <p>
                Verificamos que existem dados obrigatórios não preenchidos no
                seu cadastro.
              </p>
              <p>Complete seu cadastro antes de prosseguir.</p>
            </div>
            <Button
              color="orange"
              padding="3.1"
              rounded
              className="button-confirmar"
              type="button"
              onClick={() => handleRedirect('/meu-perfil')}
              style={{
                marginTop: '50px',
                minHeight: '20px',
              }}
            >
              CADASTRAR
            </Button>
          </ModalContentBanner>
        </ModalText>
      )} */}
    </DashboardLayout>
  );
};

export default MyCards;

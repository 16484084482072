import styled from 'styled-components';

export const TitleSection = styled.section`
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  @media only screen and (max-width: 768px) {
    height: 250px;
  }

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
      line-height: 3rem;
      margin-left: 0rem;
    }
    @media (max-width: 351px) {
      font-size: 2.5rem;
    }
    @media (max-width: 296px) {
      font-size: 2rem;
    }
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  background: var(--white);
  padding: 0px 0px;
  margin-top: 3rem;
  min-height: 60vh;
  width: 100%;
  @media only screen and (max-width: 1030px) {
    display: flex;
    flex-direction: column;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { ImLocation2 } from 'react-icons/im';
import { FaPhoneAlt } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { useToast } from '../../hooks/toast';
import framefacebook from '../../assets/Home/framefacebook.svg';
import framewhatsapp from '../../assets/Shared/framewhatsapp.svg';
import frametelegram from '../../assets/Home/frametelegram.svg';

import Button from '../Button';
import Input from '../Input';

import {
  Content,
  Main,
  FlexContainer,
  FormWrapper,
  GridContainer,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import CoreService from '../../services/core';
import Select from '../Select';

interface ContactSectionProps {
  isPage?: boolean;
  isButtonLarge?: boolean;
}

const ContactSection: React.FC<ContactSectionProps> = ({
  isPage = false,
  isButtonLarge = false,
  ...rest
}) => {
  const services = { coreService: new CoreService() };
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const categoriasOptions = [
    { value: 'Carga não validada', label: 'Carga não validada' },
    {
      value: 'Dúvidas Gratuidades/Revalidação',
      label: 'Dúvidas Gratuidades/Revalidação',
    },
    { value: 'Dúvidas app ou site', label: 'Dúvidas app ou site' },
    {
      value: 'Dúvidas Cadastro de cartão',
      label: 'Dúvidas Cadastro de cartão',
    },
    { value: 'Dúvidas Recarga', label: 'Dúvidas Recarga' },
    { value: 'Dúvidas Login', label: 'Dúvidas Login' },
    { value: 'Pedido Negado/Recusado', label: 'Pedido Negado/Recusado' },
    { value: 'Perda de cartão', label: 'Perda de cartão' },
    { value: 'Reset de Senha', label: 'Reset de Senha' },
    { value: 'Sugestões/Elogios', label: 'Sugestões/Elogios' },
    { value: 'Exclusão de Conta', label: 'Exclusão de Conta' },
    { value: 'Outros', label: 'Outros' },
  ];

  const [disable, setDisable] = useState(false);
  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        setDisable(true);

        const schema = Yup.object().shape({
          categoria: Yup.string().required('Modalidade é obrigatória'),
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um email válido'),
          phone: Yup.string(),
          comment: Yup.string().required('Mensagem é obrigatório'),
          card_number: Yup.string(),
        });
        const comment = `[${data?.categoria}] \n${data.comment}`;

        await schema.validate(data, {
          abortEarly: false,
        });

        await services.coreService.createContact(
          data.name,
          data.email,
          comment,
          data.categoria,
          data.phone || '',
          data.card_number,
        );
        addToast({
          type: 'success',
          title: 'Agradecemos seu contato',
          description: 'Sua mensagem é muito importante para nós!',
        });

        // formRef.current.reset();
        setDisable(false);

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } catch (err: any) {
        const { ...error } = err;
        setDisable(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Registro',
          description: `Ocorroeu um erro ao tentar registrar o Contato`,
        });
      }
    },
    [addToast, services],
  );

  return (
    <>
      <Main isPage={isPage} {...rest}>
        <h1 className="title">Fale Conosco</h1>
        <Content>
          <GridContainer>
            <FlexContainer isPage={isPage}>
              <div className="section-contact">
                <h2>Queremos ouvir você!</h2>
                <p>Entre em contato para dúvidas ou mais informações</p>
                <span>
                  <ImLocation2 />
                  <span className="text">Rua Aquiles Lobo 504 - </span>
                  <br />
                  <span className="bairro">Floresta - BH</span>
                </span>
                <span>
                  <FaPhoneAlt />
                  <span className="text">(31) 3248-7300</span>
                </span>
                <span>
                  <GrMail />
                  <span className="text">sac@transfacil.com.br</span>
                </span>
                <span>
                  <span className="medias">
                    <a
                      href="https://www.facebook.com/oficialtransfacil"
                      target="blank"
                    >
                      <img src={framefacebook} alt="facebook" />
                    </a>
                    <a
                      href="https://api.whatsapp.com/send?phone=553132487300&text=Olá%20Transfácil"
                      target="blank"
                    >
                      <img src={framewhatsapp} alt="whatsapp" />
                    </a>
                    <a href="https://t.me/transfaciloficial" target="blank">
                      <img src={frametelegram} alt="telegram" />
                    </a>
                  </span>
                </span>
                <span className="privacidade">
                  <p>
                    Para questões de privacidade e transparência relacionadas à
                    dados pessoais, acesse nosso Portal de Privacidade:
                  </p>
                </span>
                <span className="portalprivacidade">
                  <a
                    href="https://transfacil.md2qualitymanager.com/portal-titular-ws/#/home"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: 'var(--orange)',
                      border: '8px solid var(--orange)',
                      borderRadius: '18px',
                    }}
                  >
                    Portal de Privacidade
                  </a>
                </span>
              </div>
            </FlexContainer>
            <FlexContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormWrapper>
                  <Input name="name" placeholder="Digite o seu nome" />
                  <Input name="email" placeholder="Digite o seu email" />
                  <Input
                    phone
                    name="phone"
                    placeholder="Digite o seu telefone"
                  />
                  <Input
                    cardtransfacil
                    name="card_number"
                    placeholder="Digite o número do cartão BHBUS"
                  />
                  <div className="input-container">
                    <Select
                      name="categoria"
                      placeholder="Tipo de solicitação"
                      options={categoriasOptions}
                      className="select"
                    />
                  </div>
                  <div className="mensagem">
                    <Input
                      name="comment"
                      placeholder="Escreva a sua mensagem"
                      multiline
                    />
                  </div>
                  <Button
                    type="submit"
                    color="orange"
                    padding="2"
                    className="button"
                    disabled={disable}
                  >
                    ENVIAR
                  </Button>
                </FormWrapper>
              </Form>
            </FlexContainer>
          </GridContainer>
        </Content>
      </Main>
    </>
  );
};

export default ContactSection;

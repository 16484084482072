/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-multi-assign */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiLock, FiUser } from 'react-icons/fi';
import {
  isValidEmail,
  isValidCPF,
  formatCPF,
} from '@brazilian-utils/brazilian-utils';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';
import DashBoardLoader from '../../components/DashBoardLoader';
import { RadioButton } from '../../components/RadioButton';
import AccountsService from '../../services/accounts';
import GreenCheck from '../../assets/Login/greencheck.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';
import logo from '../../assets/Shared/logo-v2.png';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  Register,
  Company,
  Forgot,
  Dialogo,
  Modal,
  Close,
  Checkimg,
} from './styles';

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefReset = useRef<FormHandles>(null);
  const servicesProfile = { AccountsService: new AccountsService() };
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { isActive, changeLoader } = useLoader();
  const [isemail, setIsemail] = useState(null);
  const [finish, setFinish] = useState(false);
  const [checkfinish, setCheckfinish] = useState(false);
  const [buttonchange, setButtonChange] = useState('#FFC4AE');

  interface ProfileReset {
    id: number;
    email: string;
    phone: string;
  }
  const [resetpassword, setResetPassword] = useState<ProfileReset>(
    {} as ProfileReset,
  );

  const schema = Yup.object().shape({
    user: Yup.string()
      .test('login-validation', 'Email ou CPF inválido', value => {
        const isCpf = isValidCPF(value);
        if (!isCpf) {
          const isEmail = isValidEmail(value);
          if (!isEmail) {
            return false;
          }
          if (isEmail) {
            return true;
          }
        }
        if (isCpf) {
          const formatacpf = formatCPF(value, { pad: true });
          const myInput = document.getElementById('login') as HTMLInputElement;
          myInput.value = formatacpf;
          return true;
        }
        return false;
      })
      .required('Email ou CPF é obrigatório'),
    password: Yup.string().required('Senha é obrigatória'),
  });

  const schemaReset = Yup.object().shape({
    // eslint-disable-next-line camelcase
    email_or_cpf: Yup.string()
      .test('login-validation', 'Email ou CPF inválido', value => {
        const isCpf = isValidCPF(value);
        if (!isCpf) {
          const isEmail = isValidEmail(value);
          setButtonChange('#FFC4AE');
          if (!isEmail) {
            setButtonChange('#FFC4AE');
            return false;
          }
          if (isEmail) {
            setButtonChange('#Eb5e2b');
            return true;
          }
        }
        if (isCpf) {
          const formatacpf = formatCPF(value, { pad: true });
          const myInput = document.getElementById(
            'email_or_cpf',
          ) as HTMLInputElement;
          myInput.value = formatacpf;
          setButtonChange('#Eb5e2b');
          return true;
        }
        return false;
      })
      .required('Email ou CPF é obrigatório'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        changeLoader(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          user: data.user,
          password: data.password,
        });
        changeLoader(false);
      } catch (err) {
        const { ...error }: any = err;

        console.log({ err });

        changeLoader(false);

        let migrated_user = false;

        if (error.response) {
          migrated_user = error.response.data.migrated_user;
        }

        if (migrated_user) {
          const modal = document.getElementById('migrateduser');
          modal.style.display = 'block';

          await api.post('/accounts/reset-password/', {
            user: data.user,
          });

          addToast({
            type: 'success',
            title: 'E-mail enviado',
            description: 'E-mail enviado com sucesso!!',
          });
        } else {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
            return;
          }
          addToast({
            type: 'error',
            title: `${
              error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Ocorreu um erro ao realizar o Login'
            }`,
            description: `${
              error?.response?.data?.sub_message
                ? error?.response?.data?.sub_message
                : 'Verifique login e senha ou entre em contato com o Administrador'
            }`,
          });
        }
      }
    },
    [schema, signIn, addToast, changeLoader],
  );

  const handleSubmitForgot = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        await schemaReset.validate(data, {
          abortEarly: false,
        });
        const resetpass = await servicesProfile.AccountsService.GetresetPassword(
          data.email_or_cpf,
        );

        setResetPassword(resetpass.data);
        setIsemail(true);
        setButtonChange('#FFC4AE');
      } catch (err) {
        const { ...error }: any = err;
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Ocorreu um erro para recuperar a senha'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Verifique se o email ou cpf informados estão corretos ou entre em contato com o Administrador'
          }`,
        });
      }
    },
    [schemaReset, servicesProfile],
  );

  const handleSubmitCheckbox = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const mailorphone = data.RadioButton;

        await api.post('/v2/accounts/reset-password/', {
          id: resetpassword.id,
          reset_type: mailorphone === resetpassword.phone ? 'sms' : 'email',
        });

        if (mailorphone === resetpassword?.phone) {
          setFinish(true);
          setCheckfinish(false);
        } else {
          setFinish(true);
          setCheckfinish(true);
        }
      } catch (err) {
        const { ...error }: any = err;

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: `${
            error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          }`,
          description: `${
            error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          }`,
        });
      }
    },
    [addToast, finish, checkfinish, resetpassword],
  );

  function openforgot() {
    const modal = document.getElementById('forgot_password');
    setIsemail(false);
    modal.style.display = 'block';
  }

  function closeforgot() {
    const modal = document.getElementById('forgot_password');
    setFinish(false);
    modal.style.display = 'none';
  }

  useEffect(() => {
    const email = formRef.current.getFieldValue('email');
    if (email) {
      setIsemail(true);
    } else {
      setIsemail(false);
    }
  }, []);

  useEffect(() => {
    const checkboxmail = formRef.current.getFieldValue('');
    if (checkboxmail) {
      setCheckfinish(true);
    }
    if (!checkboxmail) {
      const checkboxphone = formRef.current.getFieldValue('');
      if (checkboxphone) {
        setCheckfinish(false);
      } else {
        setCheckfinish(true);
      }
    }
  }, []);

  return (
    <Container>
      {isActive && <DashBoardLoader />}
      <Content>
        <a href="https://transfacil.com.br">
          <img src={logo} alt="Transfacil" />
        </a>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça Login</h1>
          <Input
            id="login"
            name="user"
            icon={FiUser}
            className="input"
            placeholder="E-mail ou CPF"
            schema={schema}
          />
          <Input
            id="password"
            name="password"
            icon={FiLock}
            className="password"
            type="password"
            placeholder="Senha"
            schema={schema}
          />
          <Forgot>
            <button type="button" onClick={openforgot}>
              <strong> Esqueceu a senha?</strong>
            </button>
          </Forgot>

          <Button color="orange" padding="5" type="submit" className="entrar">
            Entrar
          </Button>
          <Link to="/">Voltar</Link>
        </Form>
        <Modal id="forgot_password">
          <Dialogo>
            {finish ? (
              checkfinish ? (
                <div className="containersent">
                  <Close onClick={closeforgot}>X</Close>
                  <div className="text">
                    <Checkimg>
                      <img
                        src={GreenCheck}
                        alt="CheckIMG"
                        className="greencheck"
                      />
                    </Checkimg>
                    <h1 className="password">
                      Enviaremos um link para o seu e-mail cadastrado
                    </h1>
                    <p
                      style={{
                        marginBottom: '10px',
                        marginRight: '-30px',
                      }}
                    >
                      Acesse seu e-mail {resetpassword?.email} para alterar a
                      senha.
                    </p>
                    <div className="email">
                      <span className="button">
                        <Button
                          color="orange"
                          padding=""
                          rounded
                          onClick={closeforgot}
                          className="enviar"
                        >
                          Concluir
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="containersent">
                  <Close onClick={closeforgot}>X</Close>
                  <div className="text">
                    <Checkimg>
                      <img
                        src={GreenCheck}
                        alt="CheckIMG"
                        className="greencheck"
                      />
                    </Checkimg>
                    <h1 className="password">
                      Enviaremos um link para o seu telefone cadastrado
                    </h1>
                    <p
                      style={{
                        marginBottom: '10px',
                        marginRight: '-30px',
                      }}
                    >
                      Verifique seu telefone {resetpassword?.phone} para alterar
                      a senha.
                    </p>
                    <div className="email">
                      <span className="button">
                        <Button
                          color="orange"
                          padding=""
                          rounded
                          onClick={closeforgot}
                          className="enviar"
                        >
                          Concluir
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : isemail ? (
              resetpassword?.phone ? (
                <div
                  className="checkcontainer"
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  <Close onClick={closeforgot}>X</Close>
                  <div className="text">
                    <h1 className="password">
                      Por onde você prefere receber o link de alteração de
                      senha?
                    </h1>
                    <Form
                      ref={formRefReset}
                      onSubmit={handleSubmitCheckbox}
                      placeholder="E-mail"
                      method="post"
                      className="checkbox"
                    >
                      <RadioButton
                        name="RadioButton"
                        options={[
                          {
                            id: 'EmailChecked',
                            value: resetpassword?.email,
                            label: 'Email',
                          },
                          {
                            id: 'PhoneChecked',
                            value: resetpassword?.phone,
                            label: 'Telefone',
                          },
                        ]}
                      />
                      <p
                        style={{
                          marginTop: '130px',
                          marginBottom: '40px',
                        }}
                      >
                        Caso não tenha acesso ao email ou telefone citados
                        acima, favor entrar em contato com o nosso
                        <strong> SAC (31) 3248-7300.</strong>
                      </p>
                      <span className="button">
                        <Button
                          color="orange"
                          padding=""
                          rounded
                          type="submit"
                          className="enviar"
                        >
                          Concluir
                        </Button>
                        <Button
                          color="white"
                          padding=""
                          rounded
                          type="button"
                          className="cancelar"
                          onClick={closeforgot}
                        >
                          Cancelar
                        </Button>
                      </span>
                    </Form>
                  </div>
                </div>
              ) : (
                <div
                  className="checkcontainer"
                  style={{
                    marginBottom: '10px',
                    height: '450px',
                  }}
                >
                  <Close onClick={closeforgot}>X</Close>
                  <div className="text">
                    <h1 className="password">
                      Por onde você prefere receber o link de alteração de
                      senha?
                    </h1>
                    <Form
                      ref={formRefReset}
                      onSubmit={handleSubmitCheckbox}
                      placeholder="E-mail"
                      method="post"
                      className="checkbox"
                    >
                      <RadioButton
                        name="RadioButton"
                        options={[
                          {
                            id: 'EmailChecked',
                            value: resetpassword?.email,
                            label: 'Email',
                          },
                        ]}
                      />
                      <p
                        style={{
                          marginTop: '50px',
                          marginBottom: '40px',
                        }}
                      >
                        Caso não tenha acesso ao email ou telefone citados
                        acima, favor entrar em contato com o nosso
                        <strong> SAC (31) 3248-7300.</strong>
                      </p>
                      <span className="button">
                        <Button
                          color="orange"
                          padding=""
                          rounded
                          type="submit"
                          className="enviar"
                        >
                          Concluir
                        </Button>
                        <Button
                          color="white"
                          padding=""
                          rounded
                          type="button"
                          className="cancelar"
                          onClick={closeforgot}
                        >
                          Cancelar
                        </Button>
                      </span>
                    </Form>
                  </div>
                </div>
              )
            ) : (
              <div className="containersent">
                <Close onClick={closeforgot}>X</Close>
                <div className="text">
                  <h1 className="password">Esqueceu a senha?</h1>
                  <p>Insira o Email ou CPF associado à conta.</p>
                  <Form
                    ref={formRefReset}
                    onSubmit={handleSubmitForgot}
                    placeholder="E-mail"
                    method="post"
                    className="email"
                  >
                    <span>
                      <Input
                        id="email_or_cpf"
                        name="email_or_cpf"
                        icon={FiUser}
                        className="email"
                        placeholder="Email ou CPF"
                        schema={schemaReset}
                      />
                    </span>
                    <span className="button">
                      <Button
                        style={{
                          backgroundColor: `${buttonchange}`,
                        }}
                        color="#FFC4AE"
                        padding=""
                        rounded
                        type="submit"
                        className="enviar"
                      >
                        Próximo Passo
                      </Button>
                    </span>
                  </Form>
                </div>
              </div>
            )}
          </Dialogo>
        </Modal>
        <Register>
          <p>
            Não tem conta?
            <strong>
              <a href="/cadastrar"> Cadastrar</a>
            </strong>
          </p>
        </Register>
        <Company>
          <p>Pessoa Jurídica?</p>
          <span>
            <a href="https://empresa.transfacil.com.br" target="blank">
              <button type="button">
                <strong>Acesse o Transfácil Empresas</strong>
              </button>
            </a>
          </span>
        </Company>
      </Content>
      {/* <AcceptCookies /> */}
    </Container>
  );
};

export default Login;

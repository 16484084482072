/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface ContactProps {
  isPage?: boolean;
}

interface IconsProps {
  selected?: any;
  value?: any;
}

export const Main = styled.div<ContactProps>`
  .title {
    color: var(--purple-light);
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 0em;
    ${props =>
    // eslint-disable-next-line prettier/prettier
    props.isPage &&
    css`
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        align-items: flex-start;
      `}

    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 9rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .sub-title {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 0em;
    margin-bottom: 1rem;

    ${props =>
    // eslint-disable-next-line prettier/prettier
    props.isPage &&
    css`
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        align-items: flex-start;
      `}

    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 9rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  svg {
    color: #424b5a;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--purple-light);
    font-size: 2.5rem;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 0rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const FlexContainer = styled.div<ContactProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
  text-align: left;
  align-self: flex-start;

  .icon {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    a {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
    }
    .aqui {
      color: var(--purple);
      
    }

    .divlink {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media (max-width: 768px) {
        margin-top: 2rem;
      }
    p {
      margin-right: 5px;
    }
      :hover {
        cursor: pointer;
        p {
          color: var(--orange);
        }
        .iconpdf {
          color: var(--orange);
          align-items: center;
        }
        color: var(--orange);
      }
      .iconpdf {
        cursor: pointer;
        :hover {
          color: var(--orange);
        }
        height: 30px;
        width: 30px;
    }
    }

  }

  @media (max-width: 768px) {
    margin-right: 0rem;
    text-align: center;
  }

  .contatoDiv {
    @media (max-width: 768px) {
      text-align: left;
    }
  }

  p {
    font-size: 20px;
    margin: 5px 0;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .contato {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  h1 {
    margin-bottom: 2rem;
  }

  .link {
    color: var(--purple);
    :hover {
      color: var(--orange);
    }
  }

`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  text-align: left;
  flex-direction: column;
  width: 350px;
  .input-container {
   text-align: center;
  }

  .categoria {
    padding: 1;
  }

  @media (max-width: 768px) {
    margin-top: -120px;
    margin-bottom: 320px;
    margin-right: 0;  
  }
  
  @media (max-width: 427px) {
    margin-top: -120px;
  }

  @media (max-width: 480px) {
    width: 25rem;
  }

  @media (max-width: 437px) {
    width: 22rem;
  }

  @media (max-width: 392px) {
    width: 20rem;
    margin-top: -120px;
  }

  @media (max-width: 364px) {
    width: 18rem;
    margin-top: -110px;
  }

  @media (max-width: 336px) {
    width: 16rem;
  }
`;

export const DivAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ActionActive = styled.div<IconsProps>`
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  color: ${(props) => (props.selected === 'on' ? 'white' : 'var(--purple)')};
  border: 1px solid ${(props) => (props.selected === 'on' ? 'var(--orange)' : 'var(--purple)')};
  border-radius: 16px;
  background-color: ${(props) => (props.selected === 'on' ? 'var(--orange)' : 'white')};
  .icons {
    color: ${(props) => (props.selected === 'on' ? 'white' : 'var(--purple)')};
  }
  :hover {
    background-color: var(--orange);
    color: white;
    .icons {
      color: white;
    }
  }
  margin: 5px;
  padding: 5px;
`;
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { MapProvider } from '../hooks/map';

import Home from '../pages/Home';
import Login from '../pages/Login';
import RegisterNewPassword from '../pages/ResetPassword';
import Cadastrar from '../pages/Cadastrar';
import Dashboard from '../pages/Dashboard';
import MyCards from '../pages/MyCards';
import FormOfPayment from '../pages/FormOfPayment';
import Faq from '../pages/FaqSectionPF';

import RoutesAndLines from '../pages/RoutesAndLines';
import ParaEmpresas from '../pages/ParaEmpresas';
import FaleConosco from '../pages/FaleConosco';
import TrabalheConosco from '../pages/TrabalheConosco';
import LojasLocaisRecarga from '../pages/LojasLocaisRecarga';
import Noticias from '../pages/Noticias';
import Parceiros from '../pages/Parceiros';
import CartoesRecarga from '../pages/CartoesRecarga';
import MeuPerfil from '../pages/MeuPerfil';
import MeusPedidos from '../pages/MeusPedidos';
import TermosDeUso from '../pages/TermosDeUso';
import QuemSomos from '../pages/QuemSomos';
import Favoritos from '../pages/Favoritos';
import NoticiaCompleta from '../pages/NoticiaCompleta';
import VagasDisponiveis from '../pages/VagasDisponiveis';
import RecargaMap from '../pages/RecargaMap';
import RoutesAndLinesMaps from '../pages/RoutesAndLinesMap';

import PoliticaPrivacidade from '../pages/PoliticaPrivacidade';
import PoliticaCookies from '../pages/PoliticaCookies';
import { QrCode } from '../pages/QrCode';
import { BuyQrCode } from '../pages/BuyQrCode';
import RecargaHistorico from '../pages/QrCode/RecargaHistorico';
import AcceptCookies from '../components/AcceptCookies';
import { ModalPrivacyPolicy } from '../components/ModalPrivacyPolicy';
import { ModalTermsUse } from '../components/ModalTermsUse';
import CanalDeDenuncias from '../pages/CanaldeDenuncias';

const routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" component={Login} />
        <Route
          path="/register-new-password/:code"
          component={RegisterNewPassword}
        />
        <Route path="/cadastrar" component={Cadastrar} />
        <Route path="/rotas-e-linhas" component={RoutesAndLines} />
        <Route path="/para-empresas" component={ParaEmpresas} />
        <Route path="/fale-conosco" component={FaleConosco} />
        <Route path="/trabalhe-conosco" component={TrabalheConosco} />
        <Route path="/lojas-locais-recarga" component={LojasLocaisRecarga} />
        <Route path="/noticias" component={Noticias} />
        <Route path="/parceiros" component={Parceiros} />
        <Route path="/cartoes-recarga" component={CartoesRecarga} />
        <Route path="/termos-de-uso" component={TermosDeUso} />
        <Route path="/politica-privacidade" component={PoliticaPrivacidade} />
        <Route path="/politica-cookies" component={PoliticaCookies} />
        <Route path="/quem-somos" component={QuemSomos} />
        <Route path="/noticia/:id" component={NoticiaCompleta} />
        <Route path="/vagas-disponiveis" component={VagasDisponiveis} />
        <Route path="/compliance" component={CanalDeDenuncias} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/meu-perfil" component={MeuPerfil} />
        <PrivateRoute path="/meus-cartoes" component={MyCards} />
        <PrivateRoute exact path="/qr-code" component={QrCode} />
        <PrivateRoute exact path="/qr-code/:id/buy" component={BuyQrCode} />
        <PrivateRoute
          exact
          path="/qr-code/RecargaHistorico"
          component={RecargaHistorico}
        />
        <PrivateRoute path="/meus-pedidos" component={MeusPedidos} />
        <PrivateRoute path="/cartoes-credito" component={FormOfPayment} />
        <PrivateRoute path="/favoritos" component={Favoritos} />
        <PrivateRoute path="/faq" component={Faq} />
        {/* Map Routes */}
        <MapProvider>
          <Route path="/pontos-de-recarga" component={RecargaMap} />
          <Route path="/trajetos" component={RoutesAndLinesMaps} />
          <Route path="/lojas-locais-recarga" component={LojasLocaisRecarga} />
        </MapProvider>
      </Switch>
      <AcceptCookies />
      <ModalPrivacyPolicy />
      <ModalTermsUse />
    </>
  );
};

export default routes;
